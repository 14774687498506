import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ContractDataService } from '../../shared/contractData.service';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-dlg-vorschau-weg',
  templateUrl: './dlg-vorschau-weg.component.html',
  styleUrls: ['./dlg-vorschau-weg.component.css']
})
export class DlgVorschauWegComponent implements OnInit {

  constructor(private auth : AuthService, public dialogRef: MatDialogRef<DlgVorschauWegComponent>,
    @Inject(ContractDataService)
    private contractDataService: ContractDataService,) { }

  faArrowRight = faArrowRight;
  faArrowLeft = faArrowLeft;
  faCheck = faCheck;
  isEditing : boolean = false;
  paymentFlag;
  userRole = 0;

  public contractData;
  public drittebeteiligte: boolean;
  public drittesonstige: boolean;
  public drittelastenloeschen: boolean;
  public drittesonstige2: boolean;
  public drittelastenloeschen2: boolean;
  public EPPartner: boolean;
  public EPPartnerPriv: boolean;
  public EPPartnerComp: boolean;
  public lastenloeschenIS1: boolean;
  public lastenloeschenISBigger1: boolean;
  public LSGleicheKGNr: boolean;
  public lastenloeschenUND: boolean[];
  public lastenloeschenBEISTRICH: boolean[];
  public ausserbuechelicherechte1: boolean;
  public ausserbuechelicherechte2: boolean;
  public ausserbuechelicherechte3: boolean;
  GstNrBEISTRICH: boolean[];
  GstNrUND: boolean[];
  GstNr2BEISTRICH: boolean[];
  GstNr2UND: boolean[];
  GstNr3BEISTRICH: boolean[];
  GstNr3UND: boolean[];
  GstNr4BEISTRICH: boolean[];
  GstNr4UND: boolean[];
  public LS2: boolean;
  public LS3: boolean;
  public LS4: boolean;
  public KBOOL: boolean;
  public SellPriv1: boolean;
  public SellComp1: boolean;
  public BuyPriv1: boolean;
  public BuyComp1: boolean;
  public SellPriv2: boolean;
  public SellComp2: boolean;
  public BuyPriv2: boolean;
  public BuyComp2: boolean;
  public SellPriv3: boolean;
  public SellComp3: boolean;
  public BuyPriv3: boolean;
  public BuyComp3: boolean;
  public SellPriv4: boolean;
  public SellComp4: boolean;
  public BuyPriv4: boolean;
  public BuyComp4: boolean;
  public uebertragungKommaUnd: string[];
  public UStSatzMiet: number;
  disableSubmit = false;

  ngOnInit() {
    this.auth.currentUser.subscribe((user : any) => {
      if(user) {
        this.userRole = user.userRole;
        this.paymentFlag = user.paymentFlag;
      }
      else
        this.paymentFlag = false;
    });
    this.isEditing = localStorage.getItem('isEditing') ? true : false;
    this.contractData = this.contractDataService.getContractData();
    if (this.contractData.primeType != 'kfz') {
      this.drittebeteiligteFunction();
      this.lastenloeschenFunction();
      this.ausserbuecherlicherechteFunction();
      this.LSDetailsFunction();
      this.KaefuerVerkaeuferAnteileFunction();
      this.UebertragungKommaUND();
      this.USTSatzMiet();
    }
  }

  public VerpflichtungenMieterHelper(arg: string): string {
    switch (arg) {
      case 'schnee':
        return 'Schneeräumung';

      case 'rasen':
        return 'Rasenmähen';

      case 'garten':
        return 'Gartenpflege';

      case 'reparatur':
        return 'Reparatur von Geräten';

      case 'gastherme':
        return 'Wartung der Gastherme';

      case 'stiegenhaus':
        return 'Reinigung Stiegenhaus';
    }
  }

  USTSatzMiet() {
    if (this.contractData.wegLogic.mietObjType == 'wohnung'
      || this.contractData.wegLogic.mietObjType == 'dienstwohnung'
      || this.contractData.wegLogic.mietObjType == 'zweitwohnsitz') {
      this.UStSatzMiet = 0.1;
    }
    else if (this.contractData.wegLogic.mietObjType == 'geschaeftsraum'
      || this.contractData.wegLogic.mietObjType == 'unternehmenspacht') {
      this.UStSatzMiet = 0.2;
    }
  }

  UebertragungKommaUND() {
    if (this.contractData.wegLogic.uebertragung == 'Verbindlichkeiten werden mitübernommen') {
      if (this.contractData.wegLogic.uebertragungCLfdNr) {
        let j = this.contractData.wegLogic.uebertragungCLfdNr.length;
        this.uebertragungKommaUnd = new Array(j);
        for (let i = 0; i < j; i++) {
          if (i == j - 2) this.uebertragungKommaUnd[i] = ' und';

          if (i < j - 2) this.uebertragungKommaUnd[i] = ',';
        }
      }
    }
  }

  KaefuerVerkaeuferAnteileFunction() {
    if (this.contractData.contactData.kaeuferanteile) {
      if (this.contractData.contactData.kaeuferanteile == 'Nein') this.KBOOL = true;
      else this.KBOOL = false;
    }

    if (this.contractData.anteildata.l1.seller.sellersPrivate) {
      if (this.contractData.anteildata.l1.seller.sellersPrivate.length > 0) this.SellPriv1 = true;
      else this.SellPriv1 = false;
    }

    if (this.contractData.anteildata.l1.seller.sellersCompany) {
      if (this.contractData.anteildata.l1.seller.sellersCompany.length > 0) this.SellComp1 = true;
      else this.SellComp1 = false;
    }

    if (this.contractData.anteildata.l1.buyer.buyersPrivate) {
      if (this.contractData.anteildata.l1.buyer.buyersPrivate.length > 0) this.BuyPriv1 = true;
      else this.BuyPriv1 = false;
    }

    if (this.contractData.anteildata.l1.buyer.buyersCompany) {
      if (this.contractData.anteildata.l1.buyer.buyersCompany.length > 0) this.BuyComp1 = true;
      else this.BuyComp1 = false;
    }

    if (this.LS2) {
      if (this.contractData.anteildata.l2.seller.sellersPrivate) {
        if (this.contractData.anteildata.l2.seller.sellersPrivate.length > 0) this.SellPriv2 = true;
        else this.SellPriv2 = false;
      }

      if (this.contractData.anteildata.l2.seller.sellersCompany) {
        if (this.contractData.anteildata.l2.seller.sellersCompany.length > 0) this.SellComp2 = true;
        else this.SellComp2 = false;
      }

      if (this.contractData.anteildata.l2.buyer.buyersPrivate) {
        if (this.contractData.anteildata.l2.buyer.buyersPrivate.length > 0) this.BuyPriv2 = true;
        else this.BuyPriv2 = false;
      }

      if (this.contractData.anteildata.l2.buyer.buyersCompany) {
        if (this.contractData.anteildata.l2.buyer.buyersCompany.length > 0) this.BuyComp2 = true;
        else this.BuyComp2 = false;
      }
    }

    if (this.LS3) {
      if (this.contractData.anteildata.l3.seller.sellersPrivate) {
        if (this.contractData.anteildata.l3.seller.sellersPrivate.length > 0) this.SellPriv3 = true;
        else this.SellPriv3 = false;
      }

      if (this.contractData.anteildata.l3.seller.sellersCompany) {
        if (this.contractData.anteildata.l3.seller.sellersCompany.length > 0) this.SellComp3 = true;
        else this.SellComp3 = false;
      }

      if (this.contractData.anteildata.l3.buyer.buyersPrivate) {
        if (this.contractData.anteildata.l3.buyer.buyersPrivate.length > 0) this.BuyPriv3 = true;
        else this.BuyPriv3 = false;
      }

      if (this.contractData.anteildata.l3.buyer.buyersCompany) {
        if (this.contractData.anteildata.l3.buyer.buyersCompany.length > 0) this.BuyComp3 = true;
        else this.BuyComp3 = false;
      }
    }

    if (this.LS4) {
      if (this.contractData.anteildata.l4.seller.sellersPrivate) {
        if (this.contractData.anteildata.l4.seller.sellersPrivate.length > 0) this.SellPriv4 = true;
        else this.SellPriv4 = false;
      }

      if (this.contractData.anteildata.l4.seller.sellersCompany) {
        if (this.contractData.anteildata.l4.seller.sellersCompany.length > 0) this.SellComp4 = true;
        else this.SellComp4 = false;
      }

      if (this.contractData.anteildata.l4.buyer.buyersPrivate) {
        if (this.contractData.anteildata.l4.buyer.buyersPrivate.length > 0) this.BuyPriv4 = true;
        else this.BuyPriv4 = false;
      }

      if (this.contractData.anteildata.l4.buyer.buyersCompany) {
        if (this.contractData.anteildata.l4.buyer.buyersCompany.length > 0) this.BuyComp4 = true;
        else this.BuyComp4 = false;
      }
    }
  }

  LSDetailsFunction() {
    if(this.contractData.wegLogic.immodetail.grundstuecksnummer) {
      let p = this.contractData.wegLogic.immodetail.grundstuecksnummer.length;
      this.GstNrBEISTRICH = new Array<boolean>(p);
      this.GstNrUND = new Array<boolean>(p);
      for (let i = 0; i < p; i++) {
        if (i == p - 2) this.GstNrUND[i] = true;
        else this.GstNrUND[i] = false;

        if (i < p - 2) this.GstNrBEISTRICH[i] = true;
        else this.GstNrBEISTRICH[i] = false;
      }
      if (this.contractData.contactData.AnzahlLS == '1') {
        this.LS2 = false;
        this.LS3 = false;
        this.LS4 = false;
      }
      if (this.contractData.contactData.AnzahlLS == '2') {
        this.LS2 = true;
        this.LS3 = false;
        this.LS4 = false;
      }
      if (this.contractData.contactData.AnzahlLS == '3') {
        this.LS2 = true;
        this.LS3 = true;
        this.LS4 = false;
      }
      if (this.contractData.contactData.AnzahlLS == '4') {
        this.LS2 = true;
        this.LS3 = true;
        this.LS4 = true;
      }
      if (this.LS2) {
        let q = this.contractData.wegLogic.l2immodetail.l2grundstuecksnummer.length;
        this.GstNr2BEISTRICH = new Array<boolean>(q);
        this.GstNr2UND = new Array<boolean>(q);
        for (let i = 0; i < q; i++) {
          if (i == q - 2) this.GstNr2UND[i] = true;
          else this.GstNr2UND[i] = false;

          if (i < q - 2) this.GstNr2BEISTRICH[i] = true;
          else this.GstNr2BEISTRICH[i] = false;
        }
      }

      if (this.LS3) {
        let q = this.contractData.wegLogic.l3immodetail.l3grundstuecksnummer.length;
        this.GstNr3BEISTRICH = new Array<boolean>(q);
        this.GstNr3UND = new Array<boolean>(q);
        for (let i = 0; i < q; i++) {
          if (i == q - 2) this.GstNr3UND[i] = true;
          else this.GstNr3UND[i] = false;

          if (i < q - 2) this.GstNr3BEISTRICH[i] = true;
          else this.GstNr3BEISTRICH[i] = false;
        }
      }

      if (this.LS4) {
        let q = this.contractData.wegLogic.l4immodetail.l4grundstuecksnummer.length;
        this.GstNr4BEISTRICH = new Array<boolean>(q);
        this.GstNr4UND = new Array<boolean>(q);
        for (let i = 0; i < q; i++) {
          if (i == q - 2) this.GstNr4UND[i] = true;
          else this.GstNr4UND[i] = false;

          if (i < q - 2) this.GstNr4BEISTRICH[i] = true;
          else this.GstNr4BEISTRICH[i] = false;
        }
      }
    }
  }

  ausserbuecherlicherechteFunction() {
    if (this.contractData.wegLogic.ausserbuecherlicheRechteDetail[0]) this.ausserbuechelicherechte1 = true;
    else this.ausserbuechelicherechte1 = false;

    if (this.contractData.wegLogic.ausserbuecherlicheRechteDetail[1]) this.ausserbuechelicherechte2 = true;
    else this.ausserbuechelicherechte2 = false;

    if (this.contractData.wegLogic.ausserbuecherlicheRechteDetail[2]) this.ausserbuechelicherechte3 = true;
    else this.ausserbuechelicherechte3 = false;
  }

  lastenloeschenFunction() {
    if (this.contractData.wegLogic.lastenLoeschen) {
      if (this.contractData.wegLogic.lastenLoeschen == 'Ja') {
        if (this.contractData.wegLogic.lastenLoeschenCLfdNr.length) {
          if (this.contractData.wegLogic.lastenLoeschenCLfdNr.length == 1) this.lastenloeschenIS1 = true;
          else this.lastenloeschenIS1 = false;
          if (this.contractData.wegLogic.lastenLoeschenCLfdNr.length > 1) {
            this.lastenloeschenISBigger1 = true;

            let j = this.contractData.wegLogic.lastenLoeschenCLfdNr.length;
            this.lastenloeschenBEISTRICH = new Array<boolean>(j);
            this.lastenloeschenUND = new Array<boolean>(j);
            for (let i = 0; i < j; i++) {
              if (i == j - 2) this.lastenloeschenUND[i] = true;
              else this.lastenloeschenUND[i] = false;

              if (i < j - 2) this.lastenloeschenBEISTRICH[i] = true;
              else this.lastenloeschenBEISTRICH[i] = false;
            }
          }
          else this.lastenloeschenISBigger1 = false;
        }

        if (this.contractData.anteildata.LSGleicheKGNr) this.LSGleicheKGNr = this.contractData.anteildata.LSGleicheKGNr;
      }
    }
  }

  drittebeteiligteFunction() {
    let sum: number = 0;
    let sum2: number = 0;
    if (this.contractData.wegLogic.lssonstigeRechte) {
      if (this.contractData.wegLogic.lssonstigeRechte.rightPersonArray) {
        sum += this.contractData.wegLogic.lssonstigeRechte.rightPersonArray.length;
      }
      if (this.contractData.wegLogic.lssonstigeRechte.rightCompanyArray) {
        sum += this.contractData.wegLogic.lssonstigeRechte.rightCompanyArray.length;
      }
    }
    if (this.contractData.wegLogic.LastenLoeschenDritteBeteiligte) {
      if (this.contractData.wegLogic.LastenLoeschenDritteBeteiligte.rightPersonList) {
        sum2 += this.contractData.wegLogic.LastenLoeschenDritteBeteiligte.rightPersonList.length;
      }
      if (this.contractData.wegLogic.LastenLoeschenDritteBeteiligte.rightCompanyList) {
        sum2 += this.contractData.wegLogic.LastenLoeschenDritteBeteiligte.rightCompanyList.length;
      }
    }
    if ((sum + sum2) > 0 || this.contractData.wegLogic.EPPartner == 'Nein') this.drittebeteiligte = true;
    else this.drittebeteiligte = false;

    if (sum > 0) this.drittesonstige = true;
    else this.drittesonstige = false;

    if (sum2 > 0) this.drittelastenloeschen = true;
    else this.drittelastenloeschen = false;

    if (sum > 1) this.drittesonstige2 = true;
    else this.drittesonstige2 = false;

    if (sum2 > 1) this.drittelastenloeschen2 = true;
    else this.drittelastenloeschen2 = false;

    if (this.contractData.contactData.contractOption == 'WEG') {
      if (this.contractData.wegLogic.EPPartner) {
        if (this.contractData.wegLogic.EPPartner == 'Nein') {
          this.EPPartner = true;
          if (this.contractData.wegLogic.EPList) {
            if (this.contractData.wegLogic.EPList.buyersPrivate) {
              if (this.contractData.wegLogic.EPList.buyersPrivate.length == 1) {
                this.EPPartnerPriv = true;
              }
              else this.EPPartnerPriv = false;
            }
            if (this.contractData.wegLogic.EPList.buyersCompany) {
              if (this.contractData.wegLogic.EPList.buyersCompany.length == 1) {
                this.EPPartnerComp = true;
              }
              else this.EPPartnerComp = false;
            }
          }
        }
        else this.EPPartner = false;
      }
    }
  }

  closeDlg(): void {
    this.dialogRef.close({ success: false });
  }

  closeOKDlg(type): void {
    this.dialogRef.close({ success: true, type });
  }

  transformCurrencyToNumber(input) {

    if (input !== undefined) {
      const strInput = input.replace('.', '');
      const strInput2 = strInput.replace(',', '.');
      const toNumber = parseFloat(strInput2);
      return toNumber;
    }

    return input;

  }
}
